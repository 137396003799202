import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';

const PrettoSlider = styled(Slider)({
    color: '#000000',
    fontFamily: 'Avenir',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
        backgroundColor: 'transparent'
    },
    '& .MuiSlider-thumb': {
        height: 20,
        width: 20,
        border: '5px solid #BCAA7D',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
        backgroundColor: '#BCAA7D'
    },
    '& .MuiSlider-rail': {
        opacity: 1,
        backgroundColor: '#09345B',
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#52af77',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
    '& .MuiSlider-markLabel': {
        fontSize: '14px !important',
        fontFamily: 'Avenir !important',
    }
});

function Calculator() {
    const [amount, setAmount] = React.useState(100000);
    const [months, setMonths] = React.useState(1);
    const [total, setTotal] = React.useState(0);

    const scrollToRef = () => {
        window.scrollTo(0, 600);
    };

    const executeScroll = () => scrollToRef();

    React.useEffect(() => {
        let size = 0;
        let percentage = 0.09;
        if (amount >= 100000 && amount <= 499999) {
            if (months === 1) {
                percentage = 0.09;
            } else {
                percentage = 0.20;
            }
        } else if ( amount >= 500000 && amount <= 999999) {
            if (months === 1) {
                percentage = 0.11;
            } else {
                percentage = 0.25;
            }
        } else {
            if (months === 1) {
                percentage = 0.13;
            } else {
                percentage = 0.33;
            }
        }
        switch (months) {
            case 1:
                size = 0;
                break;
            case 2:
                size = 1;
                break;
            case 3:
                size = 3;
                break;
            default:
                size = 5;
                break;
        }
        let totalYield = amount * percentage;
        setTotal(amount + totalYield);
        if (size > 1) {
            totalYield = 0;
            let initialAmount = amount;
            for (let index = 0; index < size; index++) {
                totalYield = initialAmount * percentage;
                console.log('total yield -> ', currencyFormat(totalYield));
                initialAmount = initialAmount + totalYield;
                console.log('initial amount -> ', currencyFormat(initialAmount));
            }
            setTotal(initialAmount);
        }
    }, [amount, months]);

    const amountMarks = [
        {
            value: 1,
            label: '100k'
        },
        {
            value: 2,
            label: '$500k'
        },
        {
            value: 3,
            label: '$1000k'
        },
        {
            value: 4,
            label: '$3000k'
        },
        {
            value: 5,
            label: '$5000k'
        },
    ];

    const monthsMarks = [
        {
            value: 1,
            label: '6 meses',
        },
        {
            value: 2,
            label: '1 año',
        },
        {
            value: 3,
            label: '3 años',
        },
        {
            value: 4,
            label: '5 años',
        },
    ];

    const currencyFormat = (amount) => {
        var formatter = new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
        });
        return formatter.format(amount);
    };

    return (
        <div className="form mb-5">
            <Card sx={{ minWidth: 275, maxWidth: 800, margin: 'auto', paddingLeft: 2, paddingRight: 2, paddingTop: 2 }}>
                <CardContent>
                    <div className="w-100 h-auto p-2">
                        <div className="w-100 h-auto text-center">
                            <h5>Haz crecer tu dinero con los porcentajes más altos del mercado.</h5>
                        </div>
                        <div className="w-100 h-auto mt-3">
                            {/* <div className="w-100 h-auto text-center">
                                <p>Calcúlalo tú mismo</p>
                            </div> */}
                            <div className="w-100 h-auto text-center mt-4">
                                <h5 className="medium">Monto de inversión:</h5>
                            </div>
                            <div className="w-100 h-auto text-center">
                                <div className="slider">
                                    <PrettoSlider
                                        aria-label="Temperature"
                                        defaultValue={1}
                                        step={1}
                                        marks={amountMarks}
                                        min={1}
                                        max={5}
                                        onChange={(e) => {
                                            if (e.target.value !== amount) {
                                                switch (e.target.value) {
                                                    case 1:
                                                        setAmount(100000);
                                                        break;
                                                    case 2:
                                                        setAmount(500000);
                                                        break;
                                                    case 3:
                                                        setAmount(1000000);
                                                        break;
                                                    case 4:
                                                        setAmount(3000000);
                                                        break;
                                                    default:
                                                        setAmount(5000000);
                                                        break;
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="w-100 h-auto text-center mt-4">
                                <h5 className="medium">Tiempo de inversión:</h5>
                            </div>
                            <div className="w-100 h-auto text-center">
                                <div className="slider">
                                    <PrettoSlider
                                        aria-label="Temperature"
                                        defaultValue={1}
                                        marks={monthsMarks}
                                        min={1}
                                        max={4}
                                        onChange={(e) => {
                                            if (e.target.value !== months) {
                                                setMonths(e.target.value);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="w-100 h-auto text-center mt-4">
                                <h2 className="medium">RETORNO TOTAL:</h2>
                                <h2 className="black" style={{ color: '#BCAA7D' }}>{currencyFormat(total)}</h2>
                            </div>
                            <div className="w-100 h-auto text-center mt-4">
                                <button className="invest-button medium" onClick={executeScroll}>Quiero Invertir</button>
                            </div>
                        </div>
                    </div>
                </CardContent>
                {/* <CardActions>
                    <div className="w-100 h-auto text-center mb-4">
                        <button className="w-100 p-6">Continuar</button>
                    </div>
                </CardActions> */}
            </Card>
            <div className="w-100 h-auto p-6 mt-2 text-center">
                <p className="medium" style={{ color: '#FFFFFF', fontSize: 14, margin: '0 !important' }}>
                    *El monto total calculado en el tabulador es un aproximado. Porcentaje de rendimiento y deducciones fiscales sujeto a cambio sin previo aviso.
                </p>
            </div>
        </div>
    );
}

export default Calculator;
